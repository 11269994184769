<template>
  <div>
    <list
      ref="refScheduleList"
      sort-by="HorarioId"
      :refetch-records="fetchSchedules"
      refetch-records-name="horarios"
      :actions="actions"
      key-field="horarioId"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :delete-method="deleteSchedule"
      :filters.sync="filters"
    >
      <template #cell(inicioTurno)="data">
        {{ getTime(data.item.inicioTurno) }}
      </template>
      <template #cell(finTurno)="data">
        {{ getTime(data.item.finTurno) }}
      </template>
    </list>
  </div>
</template>
<script>

import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import List from '@/components/List.vue'
import humanResources from '@/services/humanresources.service'

export default {
  components: {
    List,
  },
  setup() {
    /* Refs */
    const refScheduleList = ref(null)
    /* Services */
    const {
      fetchSchedules,
      deleteSchedule,
    } = humanResources()
    /* Data */
    const tableColumns = [
      {
        label: i18n.t('humanResources.schedule.columns.name'),
        key: 'nombre',
      },
      {
        label: i18n.t('humanResources.schedule.columns.startTurn'),
        key: 'inicioTurno',
      },
      {
        label: i18n.t('humanResources.schedule.columns.endTurn'),
        key: 'finTurno',
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
      },
    ]
    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'),
        aclAction: 'create',
        aclResource: 'Horario',
        routeName: 'apps-human-resources-schedule-edit',
        params: ['horarioId'],
        icon: 'CodepenIcon',
      },
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'delete',
        aclResource: 'Horario',
        params: ['horarioId'],
        icon: 'TrashIcon',
      },
    ])
    const actions = ref([
      {
        routeName: 'apps-human-resources-schedule-add',
        label: i18n.t('Routes.ScheduleAdd'),
        aclAction: 'create',
        aclResource: 'Horario',
        icon: 'PlusCircleIcon',
      },
    ])
    const filters = ref([])
    const getTime = time => {
      const date = new Date(Date.parse(time))
      let hours = date.getHours()
      let minutes = date.getMinutes()
      const ampm = hours >= 12 ? 'pm' : 'am'
      hours %= 12
      hours = hours || 12
      minutes = minutes < 10 ? `0${minutes}` : minutes
      const strTime = `${hours}:${minutes} ${ampm}`
      return strTime
    }
    return {
      refScheduleList,
      tableColumns,
      tableActions,
      actions,
      filters,
      fetchSchedules,
      deleteSchedule,
      getTime,
    }
  },
}
</script>
